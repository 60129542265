<script setup lang="ts">
const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();

const { cartItems, totalPrice, isEmpty, shippingTotal, subtotal } = useCart();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { sessionContext } = useSessionContext();
const freeShippingThreshold =
  sessionContext.value?.salesChannel?.customFields
    ?.custom_storefront_global_free_shipping_threshold || 75;
</script>

<template>
  <LayoutSidebar :controller="props.controller" side="right" class="relative">
    <button type="button" @click="props.controller.close"
      class="hidden rounded-full bg-white w-11 h-11 md:flex justify-center items-center text-dark absolute top-28 -left-14">
      <span class="sr-only"> Close filter </span>
      <i aria-hidden class="block icon i-custom-svg:cross text-2xl"></i>
    </button>
    <aside class="flex flex-col h-screen h-[100dvh] py-6 px-4 sm:px-6">
        <div class="flex justify-between items-center">
          <h2 class="text-left font-bold text-3xl">{{ $t('cart.header') }}</h2>
          <button type="button" @click="props.controller.close"
            class="flex rounded-full bg-white w-11 h-11 md:hidden justify-center items-center text-dark">
            <span class="sr-only"> Close filter </span>
            <i aria-hidden class="block icon i-custom-svg:cross text-2xl"></i>
          </button>
        </div>
        <ul v-if="!isEmpty" role="list" class="px-0 divide-y divide-gray-200 border-t mt-3 overflow-y-auto grow">
          <li v-for="cartItem in cartItems" :key="cartItem.id" class="flex py-6">
            <CheckoutCartItemQuick :cart-item="cartItem" has-delete-option has-quantity-selector />
          </li>
        </ul>
        <div class="flex flex-col justify-between gap-3 relative justify-self-end" v-if="!isEmpty">
          <CheckoutPromotionCode button-classes="!font-medium" />
          <div class="flex justify-between text-2xl">
            <p class="font-bold">{{ $t('cart.subtotal') }}</p>
            <SharedPrice :value="totalPrice - shippingTotal" class="text-gray-900 font-bold" />
          </div>
          <div class="flex justify-between">
            <p>{{ $t('cart.shippingEstimate') }}</p>
            <p v-if="shippingTotal < 1" class="uppercase">
              {{ $t('cart.freeShipping') }}
            </p>
            <SharedPrice v-else :value="shippingTotal" class="text-gray-900" />
          </div>
          <CheckoutShippingCalculator :current-price="subtotal" :priceLimit="freeShippingThreshold" />
          <p class="text-gray-500 text-left text-sm">
            {{ $t('cart.priceNotice') }}
          </p>
          <div class="flex flex-col md:flex-row gap-3">
            <NuxtLink class="btn w-full px-2" :class="{
              'bg-gray': isEmpty,
              'hover:bg-gray': isEmpty,
            }" :to="formatLink(`/checkout/cart`)">
              {{ $t('cart.viewCart') }}
            </NuxtLink>
            <NuxtLink class="btn btn-solid w-full px-2" :to="formatLink(isEmpty ? '' : '/checkout')">
              {{ $t('cart.toCheckout') }}
            </NuxtLink>
          </div>
        </div>
        <div v-else class="h-full flex flex-col justify-center gap-5 items-center">
          <span class="text-2xl">
            {{ $t('cart.emptyCartLabel') }}
          </span>

          <button class="btn btn-solid text-white" @click="props.controller.close">
            {{ $t('cart.discover') }}
          </button>
        </div>
    </aside>
  </LayoutSidebar>
</template>
